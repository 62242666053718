import {
    streamsStore,
    isStreamLoading,
    currentStreams,
    nextStreamPageUrl,
    hasMoreStreams
} from '../stores/streams-pagination';
import { get } from 'svelte/store';

export async function loadMoreStreams() {
    // If already loading or no more pages, don't do anything
    if (get(isStreamLoading) || !get(hasMoreStreams) || !get(nextStreamPageUrl)) {
        return;
    }

    isStreamLoading.set(true);

    try {
        const response = await fetch(get(nextStreamPageUrl));
        const result = await response.json();

        if (result.success) {
            const newStreams = result.data.data;

            // Update the stores
            streamsStore.update(streams => [...streams, ...newStreams]);
            currentStreams.update(streams => [...streams, ...newStreams]);
            nextStreamPageUrl.set(result.data.next_page_url);
            hasMoreStreams.set(!!result.data.next_page_url);
        } else {
            console.error('Error loading more streams:', result.message);
        }
    } catch (error) {
        console.error('Error loading more streams:', error);
    } finally {
        isStreamLoading.set(false);
    }
}
